export default function sliderTextBox() {
  if (document.querySelector(".slider-text-box") != null) {
    const sliderTextBox = new Swiper(".slider-text-box", {
      pagination: {
        el: ".swiper-pagination",
      },
      loop: true,
      centered: true,
      spaceBetween: 0,
      speed: 500,
      autoplay: {
        delay: 5000,
      },
      effect: "fade"
    });
  }
}

document.addEventListener("DOMContentLoaded", () => {
  sliderTextBox();
});
